import React from 'react';
import PropTypes from 'prop-types';
import './plus-minus-icon.scss';

/* eslint-disable max-len */
const PlusIcon = ({ onClick, onMouseEnter, onMouseLeave, testId }) => (
  <div
    onClick={ onClick }
    data-cy={ testId }
    className='bb-plus-icon'
    onMouseEnter={ onMouseEnter }
    onMouseLeave={ onMouseLeave }>
    <svg viewBox="0 0 99.11 99.11">
      <path d="M93.13,56.42H56.42V93.13a6.42,6.42,0,1,1-12.84,0V56.42H6.87a6.42,6.42,0,1,1,0-12.84H43.58V6.87a6.42,6.42,0,1,1,12.84,0V43.58H93.13a6.42,6.42,0,0,1,0,12.84Z" transform="translate(-0.45 -0.44)"/>
    </svg>
  </div>
);

PlusIcon.propTypes = {
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  testId: PropTypes.string
};

export default PlusIcon;
