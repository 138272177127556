/* eslint-disable max-len */
import React from 'react';
import './marbling-meater-icon.scss';

const MarblingMeaterIcon = () => (
  <svg className="marbling-meater-icon" viewBox="0 0 983.92 544.85">
    <path className="highlight" d="M611,193.94a6.89,6.89,0,0,0-9.5,2.1L440.63,448.17a69.37,69.37,0,0,0-9.77,25.47,68.06,68.06,0,0,0,134.28,22.29l49-295.06A6.89,6.89,0,0,0,611,193.94ZM498.88,512.05a29.64,29.64,0,1,1,29.64-29.64A29.64,29.64,0,0,1,498.88,512.05Z" transform="translate(-6.08 -8)"/>
    <path className="highlight" d="M670.13,39A490.2,490.2,0,0,0,498,8c-271.27,0-492,220.69-492,492H137.86V466.63H92.25a404.2,404.2,0,0,1,50.58-165.57l35.6,20.13,16.4-29-34.55-19.54A410.41,410.41,0,0,1,285.19,152.91l19.66,33.5,28.74-16.87L314.3,136.67A404.35,404.35,0,0,1,483.63,93.05l-.2,38.36,33.33.18L517,93.13a404.72,404.72,0,0,1,123.54,25.16Z" transform="translate(-6.08 -8)"/>
    <path className="light" d="M990,500C990,289.19,856.74,109,670.07,39.11l-29.56,79.18-.12.12a405.46,405.46,0,0,1,45.34,20.28l-19.56,32.54,28.57,17.17,19.89-33.08a410.4,410.4,0,0,1,123.51,120.9l-34.53,19.06,16.12,29.18,35.66-19.69a404,404,0,0,1,48.53,161.65l-45.41-.08-.34,33.33Z" transform="translate(-6.08 -8)"/>
  </svg>
);

export default MarblingMeaterIcon;
