import React from 'react';
import PropTypes from 'prop-types';
import './web-link-icon.scss';

/* eslint-disable max-len */
const WebLinkIcon = ({ onClick }) => (
  <svg className="bb-web-link-icon" onClick={ onClick } viewBox="0 0 98.22 69.46"><path d="M65.91,34.78H55.8a5.56,5.56,0,0,1-5.56,5.55H16.56A5.56,5.56,0,0,1,11,34.78V16.43a5.56,5.56,0,0,1,5.56-5.55H50.24a5.56,5.56,0,0,1,5.56,5.55H65.91A15.69,15.69,0,0,0,50.24.77H16.56A15.69,15.69,0,0,0,.89,16.43V34.78A15.69,15.69,0,0,0,16.56,50.45H50.24A15.69,15.69,0,0,0,65.91,34.78Z" transform="translate(-0.89 -0.77)"/><path d="M83.44,20.55H49.76A15.69,15.69,0,0,0,34.09,36.22H44.2a5.57,5.57,0,0,1,5.56-5.56H83.44A5.57,5.57,0,0,1,89,36.22V54.56a5.56,5.56,0,0,1-5.56,5.56H49.76a5.56,5.56,0,0,1-5.56-5.56H34.09A15.69,15.69,0,0,0,49.76,70.23H83.44A15.69,15.69,0,0,0,99.11,54.56V36.22A15.69,15.69,0,0,0,83.44,20.55Z" transform="translate(-0.89 -0.77)"/></svg>
);

WebLinkIcon.propTypes = {
  onClick: PropTypes.func
};

export default WebLinkIcon;
