import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const PlayIconLight = ({ onClick, className }) => (
  <svg
    onClick={onClick}
    className={ className ? 'bb-play-icon-light ' + className : 'bb-play-icon-light'}
    viewBox="0 0 99.19 99.19">
    <path d="M50,.4A49.6,49.6,0,1,0,99.6,50,49.66,49.66,0,0,0,50,.4Zm0,94.4A44.8,44.8,0,1,1,94.8,50,44.85,44.85,0,0,1,50,94.8Z" transform="translate(-0.4 -0.4)"/><path d="M75.92,47.91,38.35,26.7a2.4,2.4,0,0,0-3.58,2.09V71.21A2.39,2.39,0,0,0,36,73.28a2.39,2.39,0,0,0,2.39,0L75.92,52.09a2.4,2.4,0,0,0,0-4.18ZM39.57,67.1V32.9L69.86,50Z" transform="translate(-0.4 -0.4)"/>
  </svg>
);

PlayIconLight.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default PlayIconLight;
