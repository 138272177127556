import React, { Fragment } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ExternalLink from '../../util/components/ExternalLinkWithGA';

const FamilyTreeCow = ({ cow, className, descriptor }) => {
  let link = cow.ebvLink || '';
  if (!link && cow.EBV && cow.EBV.url) link = cow.EBV.url;
  const name = cow && cow.name ? cow.name : 'unknown';
  const tag = cow && cow.tag ? '#' + cow.tag : '';
  const content = (
    <Fragment>
      <p className='descriptor'>{ descriptor }</p>
      <h5>{ name }</h5>
      <p className='animal-id'>{ tag }</p>
    </Fragment>
  );
  if (link) {
    return (
      <ExternalLink href={ link }
        newTab={ true }
        testId="ancestry-link"
        className={ className } >
        { content }
      </ExternalLink>
    );
  }
  return <div className={ className }>{ content }</div>;
};

FamilyTreeCow.propTypes = {
  cow: PropTypes.shape({
    name: PropTypes.string,
    tag: PropTypes.string,
    ebvLink: PropTypes.string,
    EBV: PropTypes.object
  }),
  className: PropTypes.string,
  descriptor: PropTypes.oneOf([
    'sire', 'dam'
  ])
};

export default FamilyTreeCow;
