import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './qr-marbling.scss';
import InfoModal from '../../main/components/InfoModal';
import BeefMarblingInfo from '../../content/infoblocks/BeefMarblingInfo';
import BackToTop from '../../util/buttons/components/BackToTop';
import MarblingMeaterLogo from '../../util/logos/MarblingMeaterLogo';
import MarblingMeaterIcon from '../../util/icons/components/MarblingMeaterIcon';

const formatIMF = imf => {
  try {
    return imf.toLocaleString(undefined, {
      minimumFractionDigits: 1
    }) + '%';
  } catch (error) {
    return '-';
  }
};

class QRMarbling extends Component {
  constructor (props) {
    super(props);
    this.state = {
      visible: false,
      visibleTime: null
    };
    this.markerRef = React.createRef();
    this.getMarkerCSS = this.getMarkerCSS.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.update = this.update.bind(this);
  }
  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll);
    setTimeout(this.handleScroll, 50);
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
  }
  getMarkerCSS () {
    try {
      if (!this.state.visible) return { left: 0 };
      let pos = 0;
      if (this.props.animal.IMF < 1) {
        pos = this.props.animal.IMF * 20;
      } else if (this.props.animal.IMF > 10) {
        pos = 100;
      } else {
        pos = this.props.animal.IMF * 10;
      }
      return {
        left: `calc(${pos + '%'} + ${6 * pos / 100}px`
      };
    } catch (e) { return {}; }
  }
  handleScroll () {
    try {
      var rect = this.markerRef.current.getBoundingClientRect();
      var markerTop = rect.top;
      var markerBottom = rect.bottom + 100;
      if ((markerTop >= 0) && (markerBottom <= window.innerHeight)) {
        this.setState({ visible: true, visibleTime: new Date() });
        window.removeEventListener('scroll', this.handleScroll);
        this.update();
      }
    } catch (error) { /* */ }
  }
  update () {
    const runTime = 3000;
    const scoreElement = document.getElementById('marbling-score-text');
    if (!scoreElement) {
      return window.requestAnimationFrame(this.update);
    }
    const now = new Date();
    const timePassed = now - this.state.visibleTime;
    const rawScore =
      Math.pow((timePassed / runTime), 0.4) * this.props.animal.IMF;
    const currentScore = Math.round(rawScore * 10) / 10;
    if (currentScore < this.props.animal.IMF) {
      scoreElement.innerHTML = formatIMF(currentScore);
      window.requestAnimationFrame(this.update);
    } else {
      scoreElement.innerHTML = formatIMF(this.props.animal.IMF);
    }
  };
  render () {
    const animal = this.props.animal;
    if (!animal || !animal.IMF) return null;
    return (
      <article id='marbling-section'
        className='bb-page-wrapper'
        data-cy="marbling-section">
        <BackToTop />
        <h2>
          <MarblingMeaterLogo />
          <InfoModal contentClass="marbling-scale-modal">
            <BeefMarblingInfo />
          </InfoModal>
        </h2>
        <div className="marbling-display">
          <div className="marbling-scale">
            <div ref={ this.markerRef }
              className="marbling-ring" style={ this.getMarkerCSS() } >
              <MarblingMeaterIcon />
              <h4 className="marbling-score"
                id="marbling-score-text"
                data-cy="marbling-score-text">
                { formatIMF(animal.IMF) }
              </h4>
              <div className="line"></div>
              <div className="bobble"></div>
            </div>
          </div>
          <ul className="marbling-classes" data-cy="marbling-classes">
            <li>
              <h6>O-2%</h6>
              <p className="title">Standard</p>
              <p>(leaner marbling)</p>
            </li>
            <li>
              <h6>2+%</h6>
              <p className="title">Superior</p>
              <p>(lighter marbling)</p>
            </li>
            <li>
              <h6>4+%</h6>
              <p className="title">Classic</p>
              <p>(medium marbling)</p>
            </li>
            <li>
              <h6>6+%</h6>
              <p className="title">Prime</p>
              <p>(abundant marbling)</p>
            </li>
            <li>
              <h6>8+%</h6>
              <p className="title">Supreme</p>
              <p>(extreme marbling)</p>
            </li>
          </ul>
        </div>
        {/* <div className="border"></div> */}
      </article>
    );
  }
}

QRMarbling.propTypes = {
  animal: PropTypes.object
};

export default QRMarbling;
