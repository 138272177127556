import React from 'react';
import PropTypes from 'prop-types';
import './star-icon.scss';

/* eslint-disable max-len */
const StarIcon = ({ className }) => (
  <svg className={ className ? 'bb-star-icon ' + className : 'bb-star-icon' } viewBox='0 0 98.55 94.23'><title>star-outline-icon</title><path d='M77.93,94.61a3.63,3.63,0,0,1-1.72-.43L49.79,80.13,23.23,93.93A3.66,3.66,0,0,1,17.94,90l5.2-29.47-21.32-21a3.66,3.66,0,0,1,2.05-6.24l29.64-4.16L46.89,2.41a3.65,3.65,0,0,1,3.27-2h0a3.65,3.65,0,0,1,3.27,2.05l13.12,26.9,29.59,4.45a3.67,3.67,0,0,1,2,6.26L76.63,60.83l4.91,29.52a3.66,3.66,0,0,1-3.61,4.26ZM49.81,72.33a3.75,3.75,0,0,1,1.72.43L73.1,84.24l-4-24.1a3.66,3.66,0,0,1,1.07-3.24l17.58-17L63.57,36.3a3.65,3.65,0,0,1-2.74-2l-10.71-22L39.2,34.18a3.68,3.68,0,0,1-2.77,2L12.24,39.56,29.65,56.7a3.67,3.67,0,0,1,1,3.25L26.44,84,48.12,72.75A3.61,3.61,0,0,1,49.81,72.33ZM35.93,32.54h0Z' transform='translate(-0.72 -0.39)'/></svg>
);

StarIcon.propTypes = {
  className: PropTypes.string
};

export default StarIcon;
