import React from 'react';
import PropTypes from 'prop-types';
import './question-mark-circle.scss';

/* eslint-disable max-len */
const QuestionMarkCircle = ({ onClick, testId }) => (
  <svg onClick={ onClick } data-cy={ testId } className='bb-question-mark-circle' viewBox="0 0 98.72 98.72"><path d="M50,.64A49.36,49.36,0,1,0,99.36,50,49.36,49.36,0,0,0,50,.64Zm-.24,83c-3.55,0-6.43-2.5-6.43-5.58s2.88-5.57,6.43-5.57S56.2,75,56.2,78.07,53.32,83.65,49.76,83.65ZM55.26,54v6.11a5.5,5.5,0,0,1-11,0v-11a5.49,5.49,0,0,1,5.49-5.5c6.35,0,11.71-3.72,11.71-8.12s-5.36-8.13-11.71-8.13-10.48,3.28-11.47,6.52a5.49,5.49,0,1,1-10.51-3.2c2.57-8.42,11.61-14.31,22-14.31,12.52,0,22.7,8.58,22.7,19.12C72.46,44.41,65.13,51.94,55.26,54Z" transform="translate(-0.64 -0.64)"/></svg>
);

QuestionMarkCircle.propTypes = {
  onClick: PropTypes.func,
  testId: PropTypes.string
};

export default QuestionMarkCircle;
