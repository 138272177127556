import React from 'react';
import ArrowIcon from '../../icons/components/ArrowIcon';
import './back-to-top.scss';

const BackToTop = () => (
  <div className="back-to-top-button" onClick={
    () => window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }>
    <p className="text">Back to Top</p>
    <ArrowIcon />
  </div>
);

export default BackToTop;
