//  https://codepen.io/matttherat/pen/EeMaEw

/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import './donut-chart.scss';
import './donut-chart-keyframes.scss';
import PlusIcon from '../../icons/components/PlusIcon';
import MinusIcon from '../../icons/components/MinusIcon';

const BigDonutChart = ({ score, type, onClick, sectionIsOpen }) => {
  const percentage = Math.round(score / 4 * 10);
  const segmentStyle = {
    animation: `donut${percentage} ${score * 20 * 2}ms ease-out`
  };
  const segmentDasharray = `${percentage} ${100 - percentage}`;
  const strokeWidth = 2;
  const scoreYPos = '40%';
  let rank = 'D';
  if (score >= 100 / 3) {
    rank = 'A';
  } else if (score > 80 / 3) {
    rank = 'B';
  } else if (score > 60 / 3) {
    rank = 'C';
  }
  const className = 'big bb-svg-donut ' + rank + '-rank';
  return (
    <div onClick={ onClick }
      className={ className }
      data-cy={ `${type}-donut` }>
      <svg viewBox='0 0 40 40' className='donut'>
        <circle className='donut-hole' cx='20' cy='20' r='18' fill='#fff'></circle>
        <circle className='donut-ring' cx='20' cy='20' r='18' fill='transparent' strokeWidth={strokeWidth}></circle>
        <circle style={segmentStyle} className='donut-segment donut-segment-2' cx='20' cy='20' r='18' fill='transparent' strokeWidth={strokeWidth} strokeDasharray={segmentDasharray} strokeDashoffset='25'></circle>
        <g className='donut-text donut-text-1'>
          <text y={ scoreYPos } transform='translate(0, 2)'>
            <tspan x='50%' textAnchor='middle' className='donut-percent'>
              { score }<tspan className="out-of">/40</tspan>
            </tspan>
          </text>
          <text y="52%" transform="translate(0, 2)">
            <tspan x="50%" textAnchor="middle" className="donut-data-type">
              { type }
            </tspan>
          </text>
          <text y="60%" transform="translate(0, 2)">
            <tspan x="50%" textAnchor="middle" className="donut-data-type-label">
              quality
            </tspan>
          </text>
        </g>
      </svg>
      { sectionIsOpen ? <MinusIcon /> : <PlusIcon /> }
    </div>
  );
};

BigDonutChart.propTypes = {
  score: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['big', 'small']),
  onClick: PropTypes.func,
  hover: PropTypes.bool,
  sectionIsOpen: PropTypes.bool
};

export default BigDonutChart;
