import React from 'react';

/* eslint-disable max-len */
const AuthenticationIcon = () => (
  <svg className="authentication-icon" viewBox="0 0 72.031 99.204">
    <path d="M72.073,33.5,65.494,25.33l.523-10.473a2,2,0,0,0-1.475-2.031l-10.123-2.74-5.733-8.78A2,2,0,0,0,46.3.53L36.5,4.264,26.7.53a2,2,0,0,0-2.387.776l-5.733,8.78L8.458,12.826a2,2,0,0,0-1.475,2.031L7.506,25.33.927,33.5a2,2,0,0,0,0,2.51l6.579,8.165L6.983,54.645a2,2,0,0,0,1.475,2.03l10.122,2.74,4.089,6.263L18.63,97.349a2,2,0,0,0,3.562,1.481L36.785,80.09l14,18.71a2,2,0,0,0,1.6.8,2,2,0,0,0,1.983-2.248L50.4,65.572l4.02-6.157,10.123-2.74a2,2,0,0,0,1.475-2.03l-.523-10.474,6.579-8.165A2,2,0,0,0,72.073,33.5Zm-45.8,35.581a2,2,0,0,0,.432-.106l8.116-3.092V76.107l-11.3,14.506Zm12.548,7.051V66.12L46.3,68.971a1.972,1.972,0,0,0,.508.113l2.658,21.277ZM61.9,42.255a2.006,2.006,0,0,0-.439,1.355l.479,9.625-9.3,2.518a2,2,0,0,0-1.153.837l-5.269,8.069-9-3.431a2.008,2.008,0,0,0-1.424,0l-9.006,3.431L21.513,56.59a2,2,0,0,0-1.153-.837l-9.3-2.518.479-9.625a2.006,2.006,0,0,0-.439-1.355l-6.046-7.5,6.046-7.5a2.007,2.007,0,0,0,.439-1.354l-.479-9.625,9.3-2.519a2,2,0,0,0,1.153-.837l5.269-8.069,9.006,3.432a2.018,2.018,0,0,0,1.424,0l9-3.432,5.269,8.069a2,2,0,0,0,1.153.837l9.3,2.519-.479,9.625a2.007,2.007,0,0,0,.439,1.354l6.046,7.5Z" transform="translate(-0.484 -0.398)"/>
    <path d="M50.2,21.746a2,2,0,0,0-2.8.389L32.222,42.206,24.6,35.034a2,2,0,0,0-2.741,2.914l9.248,8.7a2,2,0,0,0,1.37.543c.055,0,.112,0,.168-.007a2,2,0,0,0,1.426-.787L50.592,24.547A2,2,0,0,0,50.2,21.746Z" transform="translate(-0.484 -0.398)"/>
  </svg>
);

export default AuthenticationIcon;
