import React from 'react';
import PropTypes from 'prop-types';
import './cow-video-view-marker.scss';
import analytics from '../../../util/analytics';

const ViewMarkers = ({ views, currentTime, duration, jumpToFunc }) => {
  return views.map(view => {
    const style = {
      left: `${view.timestamp / duration * 100}%`
    };
    let className = 'bb-video-view-marker';
    if (view.start < currentTime && view.end > currentTime) {
      className += ' in-view';
    }
    const handleClick = event => {
      event.stopPropagation();
      analytics('send', 'event', 'cowVideo', 'jump', view.label);
      jumpToFunc(view.timestamp);
    };
    return (
      <div
        key={view.timestamp + '' }
        style={style}
        className={ className }>
        <div
          onClick={handleClick}
          className="view-label">{view.label}</div>
      </div>
    );
  }).sort((a, b) => a.timestamp > b.timestamp ? 1 : -1);
};

ViewMarkers.propTypes = {
  views: PropTypes.array,
  currentTime: PropTypes.number,
  duration: PropTypes.number,
  jumpToFunc: PropTypes.func
};

export default ViewMarkers;
