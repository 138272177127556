import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PlusIcon from '../../util/icons/components/PlusIcon';
import MinusIcon from '../../util/icons/components/MinusIcon';
import './cow-data-point.scss';
import QuestionMarkCircle from '../../util/icons/components/QuestionMarkCircle';
import DownloadIcon from '../../util/icons/components/DownloadIcon';
import StarIcon from '../../util/icons/components/StarIcon';
import { getScoreDefinitions } from '../../admin/scoreDefinitions/reducer';
import { getScoreCardText } from '../../admin/scoreDefinitions/util';

const mapStateToProps = state => ({
  scoreDefinitions: getScoreDefinitions(state)
});

class CowDataPoint extends Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }
  open () {
    this.setState({ open: true });
  }
  close () {
    this.setState({ open: false });
  }
  showScoreCard () {
    // console.log('show score card');
  }
  renderSummary () {
    if (!this.state.open) return null;
    const scoreCardURL = this.props.match.url +
    `/scorecard/${this.props.attribute}`;
    return (
      <div className="summary">
        <div className="text">
          { getScoreCardText(
            this.props.attribute,
            this.props.data.score,
            this.props.scoreDefinitions) }
        </div>
        <Link to={ scoreCardURL } >
          <QuestionMarkCircle
            testId={ `score-question-mark-${this.props.attribute}` }
            onClick={ this.showScoreCard }/>
        </Link>
      </div>
    );
  }
  renderSupportingInfo () {
    if (!this.state.open) return null;
    const links = Array.isArray(this.props.data.supportingInfo) ?
      this.props.data.supportingInfo.map(item => {
        if (item.type === 'download') {
          const handleClick = (event) => {
            event.preventDefault();
            window.open(item.url);
          };
          return (
            <li key={ item.title }
              data-cy="supporting-info-item"
              className="supporting-info-item">
              <a href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                data-cy={ `authentication-document-${item.title}` }
                onClick={handleClick}>
                <DownloadIcon />
                <div>{item.title}</div>
              </a>
            </li>
          );
        }
        return null;
      }) : [];
    if (links.length < 1) return null;
    return (
      <ul className="supporting-info">
        { links }
      </ul>
    );
  }
  getStarClass () {
    if (this.props.data.score === 10) return 'grade-A';
    if (this.props.data.score === 8) return 'grade-B';
    if (this.props.data.score === 6) return 'grade-C';
    if (this.props.data.score === 4) return 'grade-D';
    return 'grade-E';
  }
  render () {
    if (this.props.attribute === 'qualityIndex') return null;
    const name =
      typeof this.props.scoreDefinitions[this.props.attribute] !== 'undefined'
        ? this.props.scoreDefinitions[this.props.attribute].name :
        this.props.attribute;
    const icon = this.state.open
      ? <MinusIcon testId="score-minus-icon" onClick={this.close} /> :
      <PlusIcon onClick={this.open} testId="score-plus-icon" />;
    const className = this.state.open
      ? 'open bb-cow-data-point' : 'bb-cow-data-point';
    let outOf = <span className='out-of'>/4</span>;
    let star = null;
    let labelClass = 'label';
    if (this.props.attribute === 'health' ||
    this.props.attribute === 'carbonSequestation' ||
    this.props.attribute === 'nutritionalValue') {
      outOf = <span className='out-of'>/8</span>;
      star = <StarIcon className={ this.getStarClass() } />;
      labelClass += ' highlight';
    }
    return (
      <div data-cy={ `score-${this.props.attribute}` } className={ className }>
        <div className='header'>
          <div className={ labelClass }>{ name } { star }</div>
          <div className="score">{ this.props.data.score }{ outOf }</div>
          { icon }
        </div>
        { this.renderSummary() }
        { this.renderSupportingInfo() }
      </div>
    );
  }
}

CowDataPoint.propTypes = {
  attribute: PropTypes.string.isRequired,
  data: PropTypes.shape({
    score: PropTypes.number.isRequired,
    supportingInfo: PropTypes.array
  }),
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }),
  scoreDefinitions: PropTypes.object
};

export default connect(mapStateToProps)(CowDataPoint);
