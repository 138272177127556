import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BigDonutChart from '../../util/charts/components/BigDonutChart';
import './cow-data-section.scss';
import CowDataPoint from './CowDataPoint';
import GoodBeefIndexLogo from '../../util/icons/components/GoodBeefIndexLogo';
import GoodBeefSheild from '../../util/icons/components/GoodBeefShield';
import BackToTop from '../../util/buttons/components/BackToTop';
import MobileBigDonutChart
  from '../../util/charts/components/MobileBigDonutChart';
import { getScoreDefinitions } from '../../admin/scoreDefinitions/reducer';
import { getElementFactors } from '../../admin/scoreDefinitions/util';

const mapStateToProps = state => ({
  scoreDefinitions: getScoreDefinitions(state)
});

class CowDataSection extends Component {
  constructor (props) {
    super(props);
    this.state = {
      openSection: null,
      version: window.innerWidth > 500 ? 'desktop' : 'mobile'
    };
    this.containerRef = React.createRef();
    this.showSection = this.showSection.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }
  componentDidMount () {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize);
  }
  showSection (openSection) {
    if (this.state.openSection === openSection) {
      this.setState({ openSection: null });
    } else {
      this.setState({ openSection });
    }
  }
  handleResize () {
    const version = window.innerWidth > 500 ? 'desktop' : 'mobile';
    this.setState({ version });
  }
  getTriangleStyle () {
    let percentageWidth = 0;
    switch (this.state.openSection) {
      case 'production':
        percentageWidth = this.state.version === 'mobile' ? 17 : 33.4;
        break;
      case 'eating':
        percentageWidth = this.state.version === 'mobile' ? 50 : 60;
        break;
      case 'environment':
        percentageWidth = this.state.version === 'mobile' ? 83 : 86.7;
        break;
    }
    return { left: percentageWidth + '%' };
  }
  renderBigDonuts () {
    let donutElements = [];
    donutElements.push(
      <MobileBigDonutChart
        key="production"
        onClick={() => this.showSection('production')}
        score={this.props.cow.production.qualityIndex}
        sectionIsOpen={ this.state.openSection === 'production' }
        type="production" />
    );
    donutElements.push(
      <MobileBigDonutChart
        key="eating"
        onClick={() => this.showSection('eating')}
        score={this.props.cow.eating.qualityIndex}
        sectionIsOpen={ this.state.openSection === 'eating' }
        type="eating" />
    );
    donutElements.push(
      <MobileBigDonutChart
        key="environment"
        onClick={() => this.showSection('environment')}
        score={this.props.cow.environment.qualityIndex}
        sectionIsOpen={ this.state.openSection === 'environment' }
        type="environment" />
    );
    return donutElements;
  }
  renderDonuts () {
    if (this.state.version === 'mobile') return this.renderBigDonuts();
    let donutElements = [];
    if (this.props.cow.production) {
      donutElements.push(
        <BigDonutChart
          key="production"
          onClick={() => this.showSection('production')}
          score={this.props.cow.production.qualityIndex}
          sectionIsOpen={ this.state.openSection === 'production' }
          type="production" />
      );
    }
    if (this.props.cow.eating) {
      donutElements.push(
        <BigDonutChart
          key="eating"
          onClick={() => this.showSection('eating')}
          score={this.props.cow.eating.qualityIndex}
          sectionIsOpen={ this.state.openSection === 'eating' }
          type="eating" />
      );
    }
    if (this.props.cow.environment) {
      donutElements.push(
        <BigDonutChart
          key="environment"
          onClick={() => this.showSection('environment')}
          score={this.props.cow.environment.qualityIndex}
          sectionIsOpen={ this.state.openSection === 'environment' }
          type="environment" />
      );
    }
    return donutElements;
  }
  renderTableContents () {
    if (this.state.openSection === null) return;
    const data = this.props.cow[this.state.openSection];
    return getElementFactors(
      this.state.openSection, this.props.scoreDefinitions
    ).map(key => {
      if (data[key]) {
        return (
          <CowDataPoint match={this.props.match}
            key={key} attribute={key} data={data[key]} />
        );
      }
      return null;
    });
  }
  renderShield () {
    if (this.state.version === 'mobile') return null;
    return (
      <Fragment>
        <div className="line"></div>
        <GoodBeefSheild score={this.props.cow.beefQualityIndex} />
      </Fragment>
    );
  }
  render () {
    if (this.props.cow.production ||
    this.props.cow.eating || this.props.cow.environment) {
      const noClass = this.state.openSection ? 'figures visible' : 'figures';
      const triangleClass = this.state.openSection ?
        'triangle' : 'hidden triangle';
      return (
        <article id='cow-data-section'
          data-cy="scores-data-section"
          ref={this.containerRef}>
          <div className='bb-page-wrapper'>
            <BackToTop />
            <h2 className="score-card-title gbi-ony">
              <span>Score Card</span>
              <div id="cow-data-section-scroll-point"></div>
            </h2>
            <div className="gbi-logo">
              <GoodBeefIndexLogo />
              {
                this.state.version === 'mobile' ?
                  <GoodBeefSheild score={this.props.cow.beefQualityIndex} /> :
                  null
              }
            </div>
            <div className="mobile-sheild-wrapper gbi-only">
              <GoodBeefSheild score={this.props.cow.beefQualityIndex} />
            </div>
            <div className='donuts'>
              { this.renderShield() }
              { this.renderDonuts() }
            </div>
            <div className={ noClass }>
              <div className="pointer-wrapper">
                <div className={ triangleClass }
                  style={ this.getTriangleStyle()}>
                </div>
              </div>
              <div className="table">
                { this.renderTableContents() }
              </div>
            </div>
            <div className="border"></div>
          </div>
        </article>
      );
    }
    return null;
  }
};

CowDataSection.propTypes = {
  cow: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      cowName: PropTypes.string
    })
  }),
  scoreDefinitions: PropTypes.object
};

export default connect(mapStateToProps)(CowDataSection);
