import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const PauseIconMedium = ({ onClick, className }) => (
  <svg
    onClick={onClick}
    className={ className ? 'bb-pause-icon-medium ' + className : 'bb-pause-icon-medium'}
    viewBox="0 0 98.26 98.26">
    <path d="M49.87,99A49.13,49.13,0,1,1,99,49.89,49.19,49.19,0,0,1,49.87,99Zm0-93.5A44.37,44.37,0,1,0,94.24,49.89,44.41,44.41,0,0,0,49.87,5.52Z" transform="translate(-0.74 -0.76)"/><path d="M38.27,71.32A2.38,2.38,0,0,1,35.89,69V30.83a2.38,2.38,0,0,1,4.76,0V69A2.38,2.38,0,0,1,38.27,71.32Z" transform="translate(-0.74 -0.76)"/><path d="M61.47,71.32A2.38,2.38,0,0,1,59.09,69V30.83a2.38,2.38,0,0,1,4.76,0V69A2.38,2.38,0,0,1,61.47,71.32Z" transform="translate(-0.74 -0.76)"/>
  </svg>
);

PauseIconMedium.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default PauseIconMedium;
