import React from 'react';
import PropTypes from 'prop-types';
import './plus-minus-icon.scss';

/* eslint-disable max-len */
const MinusIcon = ({ onClick, testId }) => (
  <div data-cy={ testId } onClick={ onClick } className='bb-minus-icon'>
    <svg viewBox="0 0 99.11 12.84"><path d="M93.13,56.42H6.87a6.42,6.42,0,1,1,0-12.84H93.13a6.42,6.42,0,0,1,0,12.84Z" transform="translate(-0.45 -43.58)"/></svg>
  </div>
);

MinusIcon.propTypes = {
  onClick: PropTypes.func,
  testId: PropTypes.string
};

export default MinusIcon;
