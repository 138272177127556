import React, { Component } from 'react';
import PropTypes from 'prop-types';
import analytics from '../analytics';

class ExternalLink extends Component {
  constructor (props) {
    super(props);
    this.captureOutboundLink = this.captureOutboundLink.bind(this);
  }
  captureOutboundLink () {
    analytics('send', 'event', 'outbound', 'click', this.props.href, {
      'transport': 'beacon',
      'hitCallback': () => { document.location = this.props.href; }
    });
  }
  render () {
    return (
      <a
        href={ this.props.href }
        data-cy={ this.props.testId }
        onClick= { this.captureOutboundLink }
        rel="noopener noreferrer"
        className={ this.props.className ? this.props.className : ''}
        target={ this.props.newTab ? '_blank' : ''} >
        { this.props.children }
      </a>
    );
  }
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  newTab: PropTypes.bool,
  className: PropTypes.string,
  testId: PropTypes.string
};

export default ExternalLink;
