import documentDefinitions from '../../../cows/documentDefinitions';

const docsObjToArray = docsObj => {
  const documents = docsObj || {};
  const keys = Object.keys(documents);
  return keys.map(key => {
    const def = documentDefinitions[key] || {
      adminName: 'unidentified', name: ''
    };
    return {
      key,
      src: documents[key].src,
      position: documents[key].position,
      keyFact: documents[key].keyFact || def.name,
      type: def.adminName
    };
  }).sort((a, b) => {
    if (typeof a.position !== 'number') return 1;
    if (typeof b.position !== 'number') return -1;
    if (a.position < b.position) return -1;
    if (a.position > b.position) return 1;
    return 0;
  }).map((doc, index) => ({
    ...doc,
    position: index + 1
  }));
};

export default docsObjToArray;
