import React, { Component } from 'react';
import { getSelectedCow } from '../reducer';
import { setSelectedCow } from '../actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './documents.scss';
import CloseIcon from '../../util/icons/components/CloseIcon';
import history, { previousPath } from '../../goodBeefIndex/gbiHistory';
import { buildDocumentArray } from '../documentDefinitions';
import DownloadIcon from '../../util/icons/components/DownloadIcon';
import WebLinkIcon from '../../util/icons/components/WebLinkIcon';
import ExternalLink from '../../util/components/ExternalLinkWithGA';

const mapStateToProps = state => ({
  cow: getSelectedCow(state)
});

const mapDispatchToProps = dispatch => ({
  selectCow: id => dispatch(setSelectedCow(id))
});

export class Documents extends Component {
  constructor (props) {
    super(props);
    this.close = this.close.bind(this);
    this.modalRef = React.createRef();
    this.closeRef = React.createRef();
  }
  componentDidMount () {
    this.props.selectCow(this.props.cowId);
    if (!this.closeRef || this.closeRef.current === null) return;
    this.closeRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  close () {
    const appHistory = this.props.history || history;
    if (previousPath() !== null) {
      appHistory.goBack(); // scrolls the page back
    } else {
      const cowURL = this.props.match.url.split('/documents')[0];
      appHistory.push(cowURL);
    }
  }
  renderDocuments () {
    const docs = this.props.cow.documents || {};
    const documents = buildDocumentArray(docs);
    return documents.map(document => {
      let icon = <DownloadIcon />;
      if (document.type === 'link') {
        icon = <WebLinkIcon />;
      }
      return (
        <li key={document.key}
          className='document'
          data-cy={ `authentication-document-${document.key}`}>
          <div className='wrapper'>
            <ExternalLink href={ document.src }
              newTab={ true } >
              { icon }
            </ExternalLink>
            <div className="text-wrapper">
              <h5 className="title">
                { document.name }
                <span className="type"> { document.type }</span>
              </h5>
              <p className="description">{ document.description }</p>
            </div>
          </div>
        </li>
      );
    });
  }
  render () {
    if (!this.props.cow) return null;
    return (
      <div id='cow-documents' data-cy="animal-documents">
        <article className='content' ref={this.modalRef}>
          <div
            className="close-wrapper"
            ref={this.closeRef}
            onClick={ this.close }>
            <CloseIcon testId="close-documents-modal"/>
          </div>
          <h4 className='mini-title'>proof of quality</h4>
          <h1 className='title'>Authentication Documents</h1>
          <p className='sub-title'>
            The GoodBeef Index authenticates quality across a range of factors
            and supplies the key documentation and certification so you
            can buy with confidence.
          </p>
          <ul className='documents  '>
            { this.renderDocuments() }
          </ul>
        </article>
      </div>
    );
  }
}

Documents.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }),
  cowId: PropTypes.string.isRequired,
  selectCow: PropTypes.func.isRequired,
  cow: PropTypes.object,
  history: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
