import React from 'react';
import PropTypes from 'prop-types';
import './cow-video-tickertape.scss';

const TickerTape = ({ currentTime, straplines }) => {
  if (!Array.isArray(straplines)) {
    return <div className='bb-video-tickertape'></div>;
  }
  const elements = straplines.map(strapline => {
    let className = 'hidden';
    if (currentTime > strapline.start && currentTime < strapline.end) {
      className = 'visible';
    }
    return (
      <h4
        className={className}
        key={strapline.timestamp + ''}>
        {strapline.label}
      </h4>
    );
  });
  return (
    <div className='bb-video-tickertape'>
      { elements }
    </div>
  );
};

TickerTape.propTypes = {
  currentTime: PropTypes.number.isRequired,
  straplines: PropTypes.array
};

export default TickerTape;
